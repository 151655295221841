@font-face {
  font-family: "Lato-Regular";
  src: local("Lato"), url(./assets/font/Lato-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Lato-Light";
  src: local("Lato"), url(./assets/font/Lato-Light.ttf) format("truetype");
}
@font-face {
  font-family: "Lato-Thin";
  src: local("Lato"), url(./assets/font/Lato-Thin.ttf) format("truetype");
}
body {
  margin: 0;
  font-family: "Lato-Regular", "Lato-Light", "Lato-Thin", sans-serif,
    -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}
ul {
  list-style: none;
}

h1 {
  font-size: 24px;
  margin: 0;
}

h2 {
  font-size: 19px;
  margin: 0;
}

h3 {
  font-size: 13px;
  margin: 0;
}

.inner-block {
  max-width: 1280px;
}
@media only screen and (min-width: 768px) {
  h2 {
    font-size: 30px;
  }

  h3 {
    font-size: 23px;
  }
}

a font,
a font u {
  color: #890400;
  text-decoration: underline;
}

a font:hover,
a font u:hover {
  color: #d20700;
}

@media only screen and (min-width: 1368px) {
  .inner-block {
    width: 1280px;
    margin: auto;
  }
}
